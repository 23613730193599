import React from 'react';

import './Container.scss';

const Container = ({children, inner, bg, className, connect}) => {
  const background = bg ? ` bg-${bg}` : false;
  return (
    <>
    { background ?
      <div className={background}>
        <div className={!inner ? ("container connect--" + connect +" "+ className) : ("container connect--" + connect) }>
          {inner ?
            <div className={"inner "+ className}>
              {children}
            </div>
            :
            children
          }
        </div>
      </div>
      :
      <div className={!inner ? ("container connect--" + connect +" "+ className) : ("container connect--" + connect) }>
        {inner ?
          <div className={"inner "+ className}>
            {children}
          </div>
          :
          children
        }
      </div>
    }


    </>
  )
}

export default Container;
