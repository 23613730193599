import React from 'react';
import {Wrapper} from '../../components/Foundation/Foundation'
import {Socials} from '../../blocks/Contact/Contact'
// import {Columns, Col} from '../../components/Foundation/Foundation'
import Logo from '../Logo/Logo';
import './Footer.scss';

const Footer = ({children, logo}) => {
  return (
    <div className="footer">
      <Wrapper>
          <div className="footer__image">
            {children}
          </div>
          <div className="logo--huge">
            <Logo color="#000"/>
          </div>
          <Socials />
      </Wrapper>
    </div>
  )
}

export default Footer;
