import WindowProps from './WindowProps.js';

const InView = (element, offset) => {
  // if(!element)return false;
  let singlePageOpen = document.querySelector('.page--single') || false;


  var bodyRect = singlePageOpen ? singlePageOpen.getBoundingClientRect() : document.body.getBoundingClientRect();
  var rect, elementTop;

  if(isNaN(element)){
    rect = element.getBoundingClientRect();
    elementTop = rect.y - bodyRect.y;
  }
  else {
    elementTop = element;
  }

  offset = (!offset) ? 0 : offset;
  var trigger_point = WindowProps.SCROLLTOP + WindowProps.SIZE.height - offset;

  if ( trigger_point > elementTop ){
    return true;
  }
  else {
    return false;
  }
}
// function InView(element, offset) {
//   // if(!element)return false;
//   let singlePageOpen = document.querySelector('.page--single') || false;
//
//
//   var bodyRect = singlePageOpen ? singlePageOpen.getBoundingClientRect() : document.body.getBoundingClientRect();
//   var rect, elementTop;
//
//   if(isNaN(element)){
//     rect = element.getBoundingClientRect();
//     elementTop = rect.y - bodyRect.y;
//   }
//   else {
//     elementTop = element;
//   }
//
//   console.log('elementTop', elementTop);
//   offset = (!offset) ? 0 : offset;
//   var trigger_point = WindowProps.SCROLLTOP + WindowProps.SIZE.height - offset;
//   console.log('trigger_point', trigger_point);
//
//
//
//     if ( trigger_point > elementTop ){
//       return true;
//     }
//     else {
//       return false;
//     }
// }

export default InView;
