import React from 'react';
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types';
// import adela from '../../../../img/adela-menu.jpg';
import Footer from '../../blocks/Footer';
import './Treatment.scss';

const Treatment = ({content}) => {
  const imgSrc = content.image.url || false;
  return (
    <>
      <h2 className="title--medium">{RichText.asText(content.item_title)}</h2>
      {RichText.render(content.information)}
      {content.price &&
        <span className="price">{content.price}</span>
      }
      {imgSrc &&
        <Footer img={imgSrc}/>
      }
    </>
  )
}


Treatment.propTypes = {
  content: PropTypes.object.isRequired
};
// Default.defaultProps = {
//   content: []
// };


export default Treatment
