import React from 'react';
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types';
import './Prices.scss';
import adela from '../../../../img/adela-pris.jpg';

import Footer from '../../blocks/Footer';

const Prices = ({content}) => {
  console.log('Default contente', content);
  const imgSrc = adela;

  let priceList = [...content];
  priceList.map((item,key)=>{
    if(!item.price){
      delete priceList[key]
    }
    return true;
  })
  console.log('priceList:', priceList);

  return (
    <>
    <h2 className="title--medium">Priser</h2>
      <div className="price-list">
        {
          priceList.map((item, key)=>(
            <div key={'prices-'+key} className="price-list__item">
              <span className="price-list__item__name">{RichText.asText(item.item_title)}</span>
              <span className="price-list__item__price">{item.price}</span>
            </div>
          ))
        }
      </div>
      <Footer img={imgSrc}/>

    </>
  )
}


Prices.propTypes = {
  content: PropTypes.array.isRequired
};
// Default.defaultProps = {
//   content: []
// };


export default Prices
