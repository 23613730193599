import React from 'react';
import PropTypes from "prop-types"
import {Wrapper} from '../Foundation/Foundation';
import './HeadLine.scss';

const Headline = ({ title, heading, align, special, subtitle, contain, className }) => {

  const Heading = heading ? `h${heading}` : "h2";
  const HeadSecond = heading ? `h${heading++}` : "h3";
  const headingAlign = align ? `align-${align}` : "";
  const _className = className || "";

  return (
    <div className={special ? "headline special" : "headline " + headingAlign + " " + _className}>
      {
        contain ?
        <Wrapper inner>
          <Heading className="title--medium ">{title}</Heading>
          {
            subtitle &&
            <HeadSecond className="headline__sub">{subtitle }</HeadSecond>
          }
        </Wrapper>
        :
        <>
          <Heading className="title--medium ">{title}</Heading>
          {
            subtitle &&
            <HeadSecond className="headline__sub">{subtitle }</HeadSecond>
          }
        </>
      }

    </div>
  )
}

Headline.propTypes = {
  title: PropTypes.string,
}

Headline.defaultProps = {
  title: ``,
}

export default Headline
