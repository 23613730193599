import React from 'react';
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types';
import Footer from '../../blocks/Footer';

const About = ({content}) => {
  console.log('Default contente', content);
  const imgSrc = content.image ? content.image.url : false;

  return (
    <>
    <h2 className="title--medium">{RichText.asText(content.title)}</h2>
    {RichText.render(content.additional_information)}
    {imgSrc &&
      <Footer img={imgSrc}/>
    }
    </>
  )
}


About.propTypes = {
  content: PropTypes.object.isRequired
};
// Default.defaultProps = {
//   content: []
// };


export default About
