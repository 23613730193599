import React from 'react';
import {DataByType} from '../../functions/Data.js';
import { RichText } from 'prismic-reactjs';
import ScrollList from '../../components/ScrollList/ScrollList';
import ContentSection from '../ContentSection/ContentSection';
import Data from '../../Data.json';
import './Treatments.scss';

const Treatments = (props) => {
  const data = DataByType("treatments");

  if(!data)return false;
  const content = data[0].data;
  const items = content.items;

  const handleMoreClick = (e,data,template) =>{
    props.getMoreData(e, data, template);
  }

  const title = RichText.asText(content.title);

  return (
    <ContentSection id="behandlingar" className={"treatments-section " + props.className} title={title} bg="blue--light">
      <ScrollList handleSingleArticleClick={handleMoreClick} items={items} />

      <div className="scroll-list">
        <div className="scroll-list__list ">
          <a href={Data.general.booking} className="btn scroll-list__item">Boka tid</a>
          <button onClick={(e)=>{handleMoreClick(e, items, "prices")} } className="btn scroll-list__item">Priser</button>
        </div>
      </div>
    </ContentSection>
  );
}

export default Treatments;
