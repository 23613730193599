import React from 'react';
import './CloseBtn.scss';

const MenuToggle = ({open, onClick}) => {
  return (
    <div onClick={onClick} className={"button-close"}>

    </div>
  )
}

export default MenuToggle;
