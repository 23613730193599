import React from 'react';

import './Hero.scss';
// import videoSrc from '../../videos/adela_header.mp4';
// import videoSrc from '../../videos/adela_header_2.mp4';
import videoSrc from '../../videos/adela_header_3.mp4';
import videoSrcMobile from '../../videos/adela_header_mobile.mp4';
import Container from '../../components/Container/Container'

import adelaHero from '../../img/adela-hero.jpg';
// <>
//   <video muted playsInline loop autoPlay className="hero__bg hidden-mobile" src={videoSrc}></video>
//   <video muted playsInline loop autoPlay className="hero__bg hidden-desktop" src={videoSrcMobile}></video>
// </>

// function getVideo(){
//   const deviceSrc = window.innerWidth < 700 ? videoSrcMobile : videoSrc;
//   const deviceClass = window.innerWidth < 700 ? "hidden-desktop" : "hidden-mobile";
//   const video = <video muted playsInline loop autoPlay className={"hero__bg " + deviceClass} src={deviceSrc}></video>
//   return video;
// }

const Hero = ({children, logo, bg}) => {

  function getVideo(){
    const deviceSrc = window.innerWidth < 700 ? videoSrcMobile : videoSrc;
    const deviceClass = window.innerWidth < 700 ? "hidden-desktop" : "hidden-mobile";
    const video = <video muted playsInline loop autoPlay className={"hero__bg " + deviceClass} src={deviceSrc}></video>
    return video;
  }



    return (
      <>
      <div className="hero scroll-point">
      {!bg ?
        getVideo()
      :
        <>
          <img src={adelaHero} alt="" className="hero__bg hidden-mobile" />
          <img src={bg} alt="" className="hero__bg hidden-desktop" />
        </>
      }
        {children}

        <img src={logo} alt="" className={!bg ? "hidden" : "hero__logo"} />
      </div>
      <Container inner bg="gray--light scroll-point">

        <div className="box--center">
          <p className="font-serif--italic font--medium">
          Adela är en klinik för estetiska och medicinska injektionsbehandlingar, och avancerad hudvård.</p>
        </div>
      </Container>
      </>
    )

}

export default Hero;
