import React from 'react';
import './MenuToggle.scss';

const MenuToggle = ({open, toggleMenu}) => {
  return (
    <div onClick={toggleMenu} className={open ? "menu-toggle open" : "menu-toggle"}>
      <div className="menu-toggle__hamburger">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="menu-toggle__cross">
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default MenuToggle;
