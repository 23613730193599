import React from 'react';
import './Footer.scss';

const Footer = ({img}) => (
  <div className="treatment__footer">
    <img src={img} alt=""/>
  </div>
)


export default Footer;
