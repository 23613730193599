import React from 'react';

import {Wrapper, Title, Columns, Col} from '../../components/Foundation/Foundation'
import Button from '../../components/Button/Button'
import Certifications from '../Certifications/Certifications'
import { RichText } from 'prismic-reactjs'
import './About.scss';
import adela from '../../img/adela-linda-johansson.jpg';
import adelaMobile from '../../img/adela-linda-mobile.jpg';
// import adela2 from '../../img/adela-stilleben.jpg';
import adela2 from '../../videos/about_nature.mp4';
import Data from '../../Data.json';
import Parallax from '../../functions/Parallax';
import {DataByTag} from '../../functions/Data.js';

// <img className="about-section__image " src={adela2} alt=""/>

const About = (props) => {

  // const content = Data.sections.about;


  const data = DataByTag("about");
  const dataConsultation = DataByTag("consultation");

  if(!data || !dataConsultation)return false;
  const p_content = data[0].data;
  const c_content = dataConsultation[0].data;


  function handleClick(e, content, template){


    props.handleSingleArticleClick(e, content, template)
  }

  return (

    <div id="om" className="about-section ">
      <Wrapper inner bg="pink--light">
        <Parallax speed={0.3} deltaY={-.2} deltaX={0} className="floater">
          <div className="about-section__image ">
            <video muted playsInline loop autoPlay  src={adela2}></video>
          </div>
        </Parallax>
        <Columns>

          <Col padd className="scroll-point">
            <Title>
              {RichText.asText(p_content.title)}
            </Title>

            {RichText.render(p_content.text)}


            <Button onClick={(e)=>handleClick(e, p_content, "about")} >Läs mer</Button>

          </Col>
          <Col flex>
            <Parallax speed={0.15} deltaY={0} deltaX={-0.3} offset={500} className="hidden-mobile">
              <img src={p_content.image.url? p_content.image.url : adela} className="about-section__image hidden-mobile" alt=""/>
            </Parallax>
            <img src={p_content.image.url? p_content.image.url : adelaMobile} className="about-section__image hidden-desktop" alt=""/>


          </Col>
        </Columns>
        <Columns reverse className="scroll-point consultation">
          <Col padd flex="col">
            <Title>{RichText.asText(c_content.title)}</Title>
            {RichText.render(c_content.text)}
            <a href={Data.general.booking} className="btn maax-on-mobile">Boka tid</a>



          </Col>
        </Columns>


            <Certifications slices={c_content.body[0].items} />



      </Wrapper>
    </div>
  );
}

export default About;
