
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

function getCoords(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

export const ScrollToEl = (idOrPos, duration, changeHash, element) => {
    changeHash=changeHash || false;
    duration=duration || 600;
    var root = document.scrollingElement || document.documentElement;
    let id = isNaN(idOrPos) ? true : false;
    let pos = getCoords(document.getElementById(idOrPos));


    const scrollEl = element ? document.getElementById(element) : root;
    const scrollY = id ? pos.top : idOrPos;

    console.log('-', scrollEl, scrollY);


    var start = root.scrollTop,
        change = scrollY - 100 - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        root.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
        else {
          if(changeHash && id){
            window.location.hash = id;
          }
        }
    };
    animateScroll();
  }

// export default ScrollTo;
