import React from 'react';

import './Header.scss';
// import LogoDouble from '../Logo/LogoDouble';
import MobileLogo from '../Logo/Logo';
import MenuToggle from '../MenuToggle/MenuToggle';
import {ContactInfo} from '../../blocks/Contact/Contact';
import Data from '../../Data.json';


const Header = ({toggleMenu, logo, mobileLogo, menuOpen, handleLinkClick}) => {

  function handleClick(e){
    handleLinkClick(e.currentTarget.getAttribute('href'));

    setTimeout(function () {
      toggleMenu();
    }, 500);
  }
  return (
    <>
    <div className="top-header">


      <a href="./" className="logo-link "><MobileLogo /></a>
      <nav className="top-header__menu">
        <a href={Data.general.booking} target="_blank" rel="noopener noreferrer" className="top-header__menu__link booking">Boka tid</a>
        <a onClick={handleClick} href="#behandlingar" className="top-header__menu__link">Behandlingar</a>
        <a onClick={handleClick} href="#behandlingar" className="top-header__menu__link">Priser</a>
        <a onClick={handleClick} href="#kontakt" className="top-header__menu__link">Kontakt</a>
        <a onClick={handleClick} href="#om" className="top-header__menu__link">Om Adela</a>
        <a href={Data.general.shop} className="top-header__menu__link">Webbshop</a>
        <ContactInfo small className="top-header__menu__footer" />
      </nav>
      <MenuToggle open={menuOpen} toggleMenu={toggleMenu}/>
    </div>
    <div className="top-header__bg"></div>
    </>
  )
}

export default Header;
