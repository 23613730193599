import React from 'react';
import {DataByType} from '../../functions/Data.js';
// import { Link } from 'prismic-reactjs';

// import ContentSection from '../ContentSection/ContentSection'
import './Contact.scss';
import {Wrapper, Columns, Col, Title} from '../../components/Foundation/Foundation'
import adelaKontakt from '../../img/adela-kontakt.jpg';
import Data from '../../Data.json';

const ContactInfo = ({className, small}) =>{

  const data = DataByType("information");
  if(!data)return false;
  const content = data[0].data;

  return (

    <div className={"contact " + className}>
      <div className={"contact__info"}>
        {small &&
          <span className="contact__title">Adelakliniken</span>
        }
        <span>Linda Johansson</span>
        <span>Leg. Sjuksköterska</span>
        <span>Spec. Sjuksköterska</span>
        {!small &&
          <>



          {content.phone &&
            <>
              <div className="break hidden-mobile"></div>
              <span className="hidden-mobile">Telefonnnummer</span>
              <span className="hidden-mobile">{content.phone}</span>
            </>
          }
          <div className="break"></div>

          {content.address &&
            <>
            {content.address.split(',').map((row,i)=>(
              <span key={"address-"+i }>{row}</span>
            ))}
            </>
          }
          </>
        }

      </div>
      <ContactLinks small={small} data={content} />
    </div>
  )
}

const ContactLinks = ({small, data}) =>{

  return (
    <div className="links">
      {!small &&
        <a href={Data.general.booking} target="_blank" rel="noopener noreferrer" className="btn-outline">Boka tid</a>
      }
      {(data.phone) &&
        <a href={"tel:"+data.phone} className="btn-outline hidden-desktop">Ring</a>
      }
      {(data.email) &&
        <a href={"mailto:"+data.email} className="btn-outline">Maila</a>
      }
      {(!small && data.map) &&
        <a href={data.map.url} target="_blank" rel="noopener noreferrer" className="btn-outline">Karta</a>
      }

    </div>
  )
}

const Socials = () =>{
  const data = DataByType("information");
  if(!data)return false;
  const content = data[0].data;

  return (

    <div className={"socials" }>
      <div className={"socials__row"}>
      {
        content.social_media.map((row,i)=>(
          <a key={"social-media-"+i } target="_blank" rel="noopener noreferrer" href={row.link.url}>{row.name}</a>

        ))
      }

      </div>
    </div>
  )
}

const Contact = (props) => {
  const data = DataByType("information");
  if(!data)return false;
  const content = data[0].data;
  // console.log('content: ', content);

  return (

    <div id="kontakt">

    <Wrapper className="contact-section" inner id="kontakt" title={"Kontakt"} bg="gray--light">
      <Columns>
        <Col>
        <Title className="hidden-desktop">
          Kontakt
        </Title>
          <img src={adelaKontakt} alt=""/>
        </Col>
        <Col padd className="scroll-point">
          <Title className="hidden-mobile">
            Kontakt
          </Title>
          {content.newsletter &&
            <div className={"contact__newsletter"}>
              <span>Nyhetsbrev från Adela</span>
              <input type="email" placeholder="Din email" />
              <div className="checkbox">
                <input id="newsletter_checkbox" name="newsletter_checkbox"type="checkbox" />
                <label htmlFor="newsletter_checkbox">
                  Jag har tagit del av Adela Klenikens Inergritspolicy gällande hantering av personupgifter
                </label>
              </div>
            </div>
          }
          <ContactInfo />
          <Socials />
        </Col>
      </Columns>

    </Wrapper>
    </div>
  );
}



export {
  Contact,
  ContactInfo,
  Socials
}
