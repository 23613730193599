import React from 'react';

import { Title, Columns, Col} from '../../components/Foundation/Foundation'
// import logo from '../../img/estetiskainjektionsradet.svg';
import { RichText } from 'prismic-reactjs'
import './Certifications.scss';
// import {DataByTag} from '../../functions/Data.js';

const Certifications = ({slices}) => {

  return (
    <Columns className="scroll-point certifications">
      <Col padd flex="col">

        {
          slices.map((content,i)=>(
            <React.Fragment key={'certifications-'+i}>
              <Title>
                {RichText.asText(content.slice_title)}
              </Title>
              {RichText.render(content.slice_text)}
              {content.slice_image.url &&
              <img className="certifications__logo" alt="Estetiska Injektionsrådet Logotyp" src={content.slice_image.url} />
              }
            </React.Fragment>
          ))
        }



      </Col>
    </Columns>
  )
}

export default Certifications;
