import React ,{Component} from 'react';
import ContentSection from '../../blocks/ContentSection/ContentSection';
import Treatments from '../../blocks/Treatments/Treatments';
import CloseBtn from '../../components/CloseBtn/CloseBtn';
import Templates from './Templates/Index.js';
import MobileLogo from '../../components/Logo/Logo';


import './SingleArticle.scss';

var camalize = function camalize(str) {
  return  (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
  {
      return chr.toUpperCase();
  });
}

class SingleArticle extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading:true
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    // console.log('nextProps', nextProps);

    if (nextProps.data !== this.props.data || nextState.loading !== this.state.loading) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState){
    // console.log('prevProps', prevProps);
    // let loading = !prevState.loading;
      setTimeout(function () {
        this.setState({
          loading:false
        })
      }.bind(this), 1000);
  }

  render(){
    // console.log('single data', this.props.data);
    if(!this.props.data){
      return false;
    }

    let classes = this.props.data.props ? this.props.data.props.classes :"";
    const template = camalize(this.props.data.template) || "Default";
    console.log('template', template);
    const Template = Templates[template];

    return (

      <section loading={ this.state.loading ? "true" : "false" } className="single-page">
        <div className="single-article inner">

          <div className="single-article__header container">
            <a href="./" className="logo-link "><MobileLogo /></a>

            <CloseBtn onClick={this.props.handleClose} />
          </div>

          <article className={classes}>
          <ContentSection>
            <div className="text-content padd">
              <Template content={this.props.data.content} callback={this.props.getMoreData} />
            </div>
          </ContentSection>
          
          {
            (template === "Treatment" || template === "Prices") &&
            <Treatments className="single-page-footer" getMoreData={this.props.getMoreData}/>

          }
          </article>

        </div>
      </section>

    );
  }
}

export default SingleArticle;
