import React from 'react';

import {Wrapper, Title, Columns, Col, Image} from '../../components/Foundation/Foundation'

import './Intro.scss';
import adela1 from '../../img/adela-1.jpg';

const Intro = (props) => {


  return (
    <div id="intro" className="intro-section scroll-point">
    <Wrapper inner bg="gray--light" connect="up">
      <Columns>
        <Col flex>
          <Image src={adela1} alt="adela intro"/>
        </Col>
        <Col padd>
          <Title>
            Första mötet med Adelakliniken
          </Title>
            <p>Ett första möte börjar alltid med en kostnadsfri konsultation för att säkerställa trygghet och säkerhet. Där går vi igenom dina förväntningar och tankar, och eventuella frågor och funderingar. Detta gör vi för att komma fram till vilken behandling som passar just dig och på så sätt uppnå ditt bästa resultat.</p>
        </Col>
      </Columns>
    </Wrapper>
    </div>
  );
}

export default Intro;
