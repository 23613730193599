import React from 'react';
import HeadLine from '../../components/HeadLine/HeadLine'
// import Container from '../../components/Container/Container'
import {Wrapper} from '../../components/Foundation/Foundation'
import './ContentSection.scss';

const ContentSection = ({children, title, subtitle, bg, dontContain, id, className}) => {
  const background = bg ? ` bg-${bg}` : false;

  return (
    <div id={id} className={"content-section scroll " + className}>
    {
      !dontContain ?
      <Wrapper inner bg={background} className={className}>
        {title &&
          <HeadLine title={title} subtitle={subtitle} />
        }
        {children}
      </Wrapper>
      :
        <>
        {title &&
          <HeadLine title={title} subtitle={subtitle} />
        }
        {children}
        </>
    }

    </div>
  )
}

export default ContentSection;
