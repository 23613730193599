import React, {Component} from 'react';
import logo from './img/Adela_White.svg';
import mobileLogo from './components/Logo/Logo';
import './styles/App.scss';
import SingleArticle from './components/SingleArticle/SingleArticle'
// import Container from './components/Container/Container'
import Header from './components/Header/Header'
import Hero from './components/Hero/Hero'
// import ScrollList from './components/ScrollList/ScrollList'
import Footer from './components/Footer/Footer'
// import ContentSection from './blocks/ContentSection/ContentSection'
// import {Wrapper, Title, Columns, Col} from './components/Foundation/Foundation'
import Treatments from './blocks/Treatments/Treatments'
import About from './blocks/About/About'
import Intro from './blocks/Intro/Intro'
import {Contact} from './blocks/Contact/Contact'
import ScrollPoints from './functions/ScrollPoints';
import {ScrollToEl} from './functions/ScrollToEl';
import Cookies from "./components/Cookies/Cookies"

// import Data from './Data.json';

// import adelaHero from './img/adela-hero.jpg';
// import adelaHero from './img/adela-hero2.jpg';
// import adela1 from './img/adela-1.jpg';
// import adelaMenu from './img/adela-menu.jpg';
import adelaFooter from './img/adela-footer.jpg';


class App extends Component {

  state = {
    activeArticle:false,
    activeId:false,
    menuOpen: false
  }



  toggleMenu = () => {
    this.setState({
      menuOpen:!this.state.menuOpen
    })
  }
  handleMenuClick = (href) => {
    const _id = href.split("#")[1];

    ScrollToEl(_id)
  }

  componentDidUpdate = ()=>{
    if(this.state.singlePageOpen || this.state.menuOpen){
      document.body.classList.add('lock');
    }
    else {
      document.body.classList.remove('lock');
    }
  }

  componentDidMount = ()=>{

    ScrollPoints(['.scroll-point']);


// React.useEffect(()=>{
    // window.addEventListener('scroll', WindowProps.handleScroll(););
  // })
  }

  closeArticle = ()=>{
    this.setState({
      activeId: false,
      singlePageOpen: false,
    })

    setTimeout(function () {
      this.setState({
        activeArticle: false
      })
    }.bind(this), 600);
  }

  getMoreData = (e, content, template)=>{
    let target = e.currentTarget;
    console.log('e:', e);


    // let state = !this.state.singlePageOpen;
    let state = true;

    let objData = {
      template: template || false,
      content: content
    }


    this.setState({
      articleIsLoading: true,
      activeArticle: objData,
      prismic:true
    })

    target.classList.add('animate');

    setTimeout(function () {
      this.setState({
        singlePageOpen: state
      })
    }.bind(this), 500);

    setTimeout(function () {
      target.classList.remove('animate');
    }, 700);
  }

  render(){
    // console.log('Data: ', Data);
    // const listitems = [{title:"Profhilo®"},{title:"DermaPen®"},{title:"Neostrata®"},{title:"Restylane® (filler) "},{title:"Restylane® Skinbooster"},{title:"Botox"},{title:"Belkyra® "},{title:"Sklerosering"}];
    return (
      <div className={this.state.menuOpen ? "page menu-open" : "page"}>
        <Header handleLinkClick={this.handleMenuClick} logo={logo} mobileLogo={mobileLogo} menuOpen={this.state.menuOpen} toggleMenu={this.toggleMenu}/>

        <div className={this.state.singlePageOpen ? "page-wrapper single-page-open" : "page-wrapper"}>

          <div className="main">

            <Hero logo={logo} />

            <Intro />


            <Treatments getMoreData={this.getMoreData} />



            <Contact />
            <About handleSingleArticleClick={this.getMoreData}/>

            <Footer logo={logo}>
              <img src={adelaFooter} alt=""/>
            </Footer>


          </div>
          <SingleArticle
            getMoreData={this.getMoreData}
            key={"s-a"}
            prismic={this.state.prismic}
            data={this.state.activeArticle}
            handleClose={this.closeArticle}
            activeId={this.state.activeId}
            open={this.state.singlePageOpen}
          />
        </div>
        <Cookies />
      </div>
    );
  }
}

// {
//   Object.keys(Data.sections).map((key)=>{
//     const section = Data.sections[key];
//     console.log('section:',section);
//     return (
//       <ContentSection title={section.header[0].title} bg="blue--light">
//
//         <div className="btn" onClick={this.openSingleArticle} articleId={key}>Läs mer</div>
//       </ContentSection>
//
//     )
//   })
// }

export default App;
