import React from 'react';
import HeadLine from '../../components/HeadLine/HeadLine'

import './Foundation.scss';

const Wrapper = ({children, inner, bg, className, connect}) => {
  const background = bg ? ` bg-${bg}` : false;
  return (
    <>
    { background ?
      <div className={background}>
        <div className={!inner ? ("wrapper connect--" + connect +" "+ className) : ("wrapper connect--" + connect) }>
          {inner ?
            <div className={"inner "+ className}>
              {children}
            </div>
            :
            children
          }
        </div>
      </div>
      :
      <div className={!inner ? ("wrapper connect--" + connect +" "+ className) : ("wrapper connect--" + connect) }>
        {inner ?
          <div className={"inner "+ className}>
            {children}
          </div>
          :
          children
        }
      </div>
    }


    </>
  )
}

const Columns = ({children, cols, reverse, className})=>{
  const _class = className || "";

  return (
    <div className={_class +" flex-cols" + (cols ? "--" + cols : "") + (reverse ? " flex-reverse" : "" )}>
      {children}
    </div>
  )
}
const Col = ({children, padd, flex, size, className})=>{
  const _class = className || "";

  return (
    <div className={_class +" flex-cols__col" + (size ? "--"+size : "") + (padd ? " padd" : "" ) + (flex ? " flex--" + flex : "" )} >
      {children}
    </div>
  )
}

const Title = ({children, subtitle, size, className})=>{
  return (
    <HeadLine title={children} subtitle={subtitle} className={className} />
  )
}

const Image = ({src, alt})=>{
  return (
    <img src={src} alt={alt}/>
  )
}

const ReadMore = ({children, color})=>{
  const ref = React.createRef();

  function handleClick(e){
    let el = ref.current;
    if(ref.current.classList.contains('read-more--open')){
      el.classList.remove('read-more--open');
    }
    else {
      el.classList.add('read-more--open');

    }
  }

  return (
    <div ref={ref} className={"read-more" + (color ? (" bg-" + color): "")} >
      {children}
      <div className="read-more__toggle" onClick={handleClick}>
        Läs mer
      </div>
    </div>
  )
}

// export default ContentSection;
export {
  Title,
  ReadMore,
  Wrapper,
  Image,
  Columns,
  Col
}
