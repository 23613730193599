import React, {Component} from 'react';
import './Cookies.scss';

class Cookies extends Component {

  constructor(props){
    super(props);
    this.message = props.children || "Den här webbplatsen använder cookies. Cookies används för att underlätta underhållsarbete och bidra till att förbättra besöksupplevelsen. Genom att göra inställningar i webbläsaren kan du blockera cookies. Det kan innebära att vissa tjänster på webbplatsen inte fungerar samt att du får se detta meddelande ofta.";

    this.checkLocalStorage = this.checkLocalStorage.bind(this);
    this.closeCookie = this.closeCookie.bind(this);
    this.saveToLocalStorage = this.saveToLocalStorage.bind(this);

    this.cookieString = 'pageCookiesApproved';

    this.state = {
      cookiesApproved: this.checkLocalStorage()
    }
  }


  closeCookie(){
    this.saveToLocalStorage();
  }

  saveToLocalStorage(){
    localStorage.setItem(this.cookieString, true);

    this.setState({
      cookiesApproved: true
    })
  }

  checkLocalStorage(){
    const value = localStorage.getItem(this.cookieString) || false ;
    return value;
  }


  render(){
    // console.log('this.state.cookiesApproved', this.state.cookiesApproved);
    return (
      <div className={this.state.cookiesApproved ? "cookies" : "cookies cookies--not-approved"}>
        <div className="cookies__content">
          <p>{ this.message }</p>
          <div className="btn" onClick={this.closeCookie}>Ok</div>
        </div>
      </div>
    )
  }
}

export default Cookies;
