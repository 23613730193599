import InView from './InView.js';
import WindowProps from './WindowProps.js';

let waitingOnAnimRequest = false;

function ScrollPoints(animationSelectors) {


  window.addEventListener('scroll', (e)=>handleScroll( e ));

  const animChecker = (target) => {
    // Loop over our selectors
    animationSelectors.forEach(selector => {
      // Loop over all matching DOM elements for that selector
      target.querySelectorAll(selector).forEach(element => {
        setClasses(element);
      })
    })
  }

  let setClasses = (element) => {
    let offset = element.getAttribute('offset') || 0;

    if(isNaN(offset)){
      if ( offset.indexOf('%') >= 0 ){
        offset = offset.split('%')[0];
        offset = element.clientHeight * (offset/100);
      }
      else if ( offset.indexOf('vh') >= 0 ){
        offset = offset.split('vh')[0];
        offset = WindowProps.SIZE.height * (offset/100);
      }
    }

    if( InView(element, offset) ) {
      element.classList.add('scroll-point--active');
      element.classList.remove('scroll-point--inactive');
      const header = document.querySelector('.top-header');
      if(element.classList.contains('hero') && !header.classList.contains('invert')){
        header.classList.add('invert');
      }

      if( InView(element, (offset + WindowProps.SIZE.height + element.clientHeight)*1) ) {
        element.classList.add('scroll-point--inactive');
        if(element.classList.contains('hero')){
          // console.log('hero');
          // const header = document.querySelector('.top-header');
          // console.log('header', header);
          if(header.classList.contains('invert')){
            header.classList.remove('invert')
          }
        }
      }
    }

    else {
      element.classList.remove('scroll-point--active');
      element.classList.remove('scroll-point--inactive');
    }
  }

  let handleScroll = ({target}) => {
    if (!waitingOnAnimRequest) {
      window.requestAnimationFrame(() => {
        WindowProps.handleScroll();
        animChecker(target);
        waitingOnAnimRequest = false;
      })
      waitingOnAnimRequest = true;
    }
  }

  animChecker(document);

}

export default ScrollPoints;
