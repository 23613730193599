import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/Button/Button'
import './ScrollList.scss';
import { RichText } from 'prismic-reactjs'


const ScrollList = ({items, message, handleSingleArticleClick}) => {

  const list_message = message || "Swipa för att se fler";

  const wrapItems = (items) => {
    const arrLength = items.length;
    const endOfLine = arrLength%2 === 0 ? arrLength/2 : (arrLength + 1)/2;
    let wrappedItems = {};
    let rowCount = 0;
    items.map((item, i)=>{

      if(i%endOfLine === 0){
        rowCount++;
        // console.log('ny rad', i%endOfLine);
        wrappedItems[rowCount] = wrappedItems[rowCount] || [];
        wrappedItems[rowCount].push(item);
      }
      else {
        wrappedItems[rowCount].push(item);
      }
      return true;
    })

    return wrappedItems;

  }

  const handleClick = (e, content, template) => {

    handleSingleArticleClick(e, content, template)
  }

  return (

    <div className="scroll-list">
      <div className="scroll-list__list">
        {
          Object.keys(wrapItems(items)).map((i)=> {
            return (
              <div key={"row-" + i}>
              {
                wrapItems(items)[i].map((item, i)=>(
                  <Button key={"behandling-"+ item.item_title + '-' + i} onClick={(e)=>handleClick(e, item, "treatment")} className="scroll-list__item" data={item.information}>{RichText.asText(item.item_title)}</Button>
                ))
              }
              </div>
            )
          })
        }
      </div>
      <div className="scroll-list__message">
        {list_message}
      </div>
    </div>
  )
}

ScrollList.propTypes = {
  items: PropTypes.array.isRequired
};

ScrollList.defaultProps = {
  items: []
};

// <a onClick={handleSingleArticleClick} article-id={"about"} href={item.href} key={"behandling-"+ item.name + '-' + i} className="scroll-list__item">{item.title}</a>

export default ScrollList;
